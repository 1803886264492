import React from 'react';
import Helmet from "react-helmet";

function Head() {
    return (
        <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Simbastack</title>
            <meta name="title" content="Simbastack" />
            <meta name="description" content="Simbastack helps you launch your NFT, Metaverse and other Web3 related ideas quickly. Simbastack has been building on Ethereum for 5 years and holds a lot of expertise in the blockchain space which helps cut down your go-to-market time." />
            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.simbastack.com/" />
            <meta property="og:title" content="Simbastack" />
            <meta property="og:description" content="Simbastack helps you launch your NFT, Metaverse and other Web3 related ideas quickly. Simbastack has been building on Ethereum for 5 years and holds a lot of expertise in the blockchain space which helps cut down your go-to-market time." />
            <meta property="og:image" content="/facebookimage.png" />
            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.simbastack.com/" />
            <meta property="twitter:title" content="Simbastack" />
            <meta property="twitter:description" content="Simbastack helps you launch your NFT, Metaverse and other Web3 related ideas quickly. Simbastack has been building on Ethereum for 5 years and holds a lot of expertise in the blockchain space which helps cut down your go-to-market time." />
            <meta property="twitter:image" content="/twitterimage.png" />
            <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />


            {/* <script src="https://res.cloudinary.com/nftphotographers-test/raw/upload/v1700140426/chat-bot/main.2b755cef_sggh5y.js"></script>
            <link rel="stylesheet" type="text/css" href="https://res.cloudinary.com/nftphotographers-test/raw/upload/v1700140465/chat-bot/main.fe9dd3fe_kj5j5v.css" ></link> */}
            <script defer src="https://res.cloudinary.com/nftphotographers-test/raw/upload/v1700976695/chat-bot/26%20nov/main.fee07ec3_qxftx3.js"></script>
            <link rel="stylesheet" type="text/css" href="https://res.cloudinary.com/nftphotographers-test/raw/upload/v1700976691/chat-bot/26%20nov/main.23aab380_mmzh0o.css"></link>

        </Helmet>
    );
}

export default Head;
